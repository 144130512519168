<template>
  <modal :visible="show" :close="onClose" :className="`form ${modalClass || ''}`">
    <div class="header">
      <div>{{ title || t('common.attention') }}</div>
      <div class="modal-close" @click="onClose"><Icon name="close"/></div>
    </div>
    <div class="body attention-modal-text">
      <div class="text-center my-3">
          <slot name="top">
              <Icon name="attention" />
          </slot>
      </div>
      <div class="attention-modal-title text-center mb-3">
        <slot name="title"></slot>
      </div>
      <div class="text-center">
        <slot name="text"></slot>
      </div>
      <slot name="approveText">

          <div class="text-center mt-3">{{ t('confirmModal.to_approve_1') }}</div>
          <div class="text-center mb-3" v-html="t('confirmModal.to_approve_2')"></div>

      </slot>
      <BaseInputString v-model="approve"></BaseInputString>
    </div>
    <div class="footer">
      <BaseButton class="mr-2"
                  @click="onApprove"
                  :loading="loadingApprove"
                  :disabled="approve !== 'I understand'"
                  v-bind="buttons?.apply?.attrs">
          {{ buttons?.apply?.text || t('common.apply') }}
      </BaseButton>
      <BaseButton outline @click="onClose" v-bind="buttons?.cancel?.attrs">
          {{ buttons?.cancel?.text || t('common.cancel') }}
      </BaseButton>
    </div>
  </modal>
</template>

<script>

import { useI18n } from 'vue-i18n';
import { ref, watch} from 'vue';
import Icon from 'Common/components/svg';
import BaseButton from 'Common/components/controls/BaseButton';
import BaseInputString from 'Common/components/controls/BaseInputString';

export default {
  components: {Icon, BaseButton, BaseInputString},
  name: 'ConfirmationModal',
  props: {
    show: Boolean,
    loadingApprove: Boolean,
    title: String,
    modalClass: String,
    buttons: Object,      // button customizations : {cancel: {text, attrs}, apply: {text, attrs}}
  },
  emits: ['approve', 'close'],
  setup(props, {emit}){
    const { t } = useI18n({ useScope: 'global' });
    const approve = ref("");

    watch(() => props.show, function (){
      approve.value = "";
    });
    
    return {
      t,
      approve,
      onApprove,
      onClose
    }
    
    function onApprove(){
      emit('approve');
    } 
    
    function onClose(){
      emit('close');
    } 
  }
}
</script>

<style scoped lang="scss">
  .attention-modal-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .attention-modal-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
</style>