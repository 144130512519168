<template>
  <InvalidProperty v-if="!propsValidation.isValid" :message="propsValidation.message" />
  <template v-else>
    <div part="analysis--wrapper" class="analysis--wrapper">
      <div part="analysis--inner" class="analysis--inner" v-if="props.report">
        <AnalysisChannelReport :name="showOneChannel ? '' : 'Channel 0'" :report="props.report" :channel="0" :localeTexts="Locale" />
        <div part="analysis-text-container" class="analysis-text-container">
          <div part="analysis-text" class="analysis-text">{{Locale.analysisSummaryReport}}</div>
        </div>
        <AnalysisChannelReport v-if="!showOneChannel" name="Channel 1" :report="props.report" :channel="1" :localeTexts="Locale" />
      </div>
    </div>
  </template>
</template>

<script>
const propsDefinition = {
  report: {
    required: true,
    typeof: "object",
    existProps: ['reports', 'segments']
  },
  localeTexts: {
    required: false,
    typeof: "object",
    existProps: ['tags', 'energy', 'stress', 'mentalEffort', 'confidence', 'excitement', 'distress', 'risk', 'emotionDiamond'],
  },
  language: {
    required: false,
  }
}
</script>
<script setup>
import AnalysisChannelReport from './AnalysisChannelReport.ce.vue'
import InvalidProperty from '@/components/InvalidProperty.vue';
import {getDefinePropsObject, getLocaleTexts, validateProps} from "@/helpers/helper.js";
import {computed} from "vue";
import {isSecondChannelValid} from "@/utils/emlo.js";

const stringifyProps = defineProps(getDefinePropsObject(propsDefinition));

const {propsValidation, props} = validateProps(propsDefinition, stringifyProps);
const Locale = getLocaleTexts(props, (locale) => ({...locale.analysisReport, emotionDiamond: locale.emotionDiamond}));

const showOneChannel = computed(() => {
  if(!props.value.report) return true;
  
  return !isSecondChannelValid(props.value.report);
})

</script>

<style lang="scss" src="./AnalysisReport.scss" />
