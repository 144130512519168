<template>
  <div class="base-input" :class="this.class">
    <div v-if="name" class="base-input-header">
      <div class="base-input-label">
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div>
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <label class="base-input-container" :class="{'base-input-disabled': disabled, 'base-input-money': money, 'top-filter-style': topFilterStyle}">
      <span v-if="money && moneyPosition === 'left'">{{moneySymbol}}</span>
      <span v-if="customIconsPre?.length > 0" class="mr-1">
        <span v-for="item in customIconsPre">
          <component :is="item.component" v-bind="item.props"/>
        </span>
      </span>
      <span v-if="showPlaceholder"
            :style="{paddingLeft: (customIconsPre ? 30 : 0) + 'px'}"
            class="input-placeholder">
        {{placeholder}}
      </span>
      <slot>
        <input
            v-model="value"
            class="base-input-control"
            :disabled="disabled"
            @blur="onBlur"
            @keypress="onKeypress"
            @change="onChange"
            @input="onChange"
            :min="min"
            :max="max"
            v-bind="attrs"
            v-on="$attrs"
            :type="inputType">
      </slot>
      <span class="money-symbol" v-if="money && moneyPosition === 'right'">{{moneySymbol}}</span>
      <span v-if="customIcons?.length > 0" class="ml-1">
        <span v-for="item in customIcons">
          <component :is="item.component" v-bind="item.props"/>
        </span>
      </span>
    </label>
    <div v-if="error" class="base-input-error">
      <Icon name="error"/>
      <div v-html="error"></div>
    </div>
  </div>
</template>

<script>

import Icon from 'Common/components/svg';

export default {
  components: {Icon},
  name: 'BaseInputString',
  props: {
    name: String,
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {type: Boolean, required: false, default: false},
    modelValue: String | Number,
    money: {
      type: Boolean,
      default: false,
    },
    moneySymbol: {
      type: String,
      default: "$",
    },
    moneyPosition: {
      type: String,
      default: "left",
    },
    customIconsPre: Array, // [ {component, props} ]
    customIcons: Array, // [ {component, props} ]
    min: Number,
    max: Number,
    error: String,
    class: String,
    type: {type: String, default: "text"},
    attrs: {type: Object, required: false, default: {}},
    placeholder: [String, Boolean],
    topFilterStyle: Boolean,
  },
  emits: ['update:modelValue', 'blur'],
  watch: {
    value: {
      handler(n, o) {
        const ml  = this.attrs?.maxlength;
        if (ml > 0 && String(this.value || '0').length > ml) {
          this.value = o;
        }
      },
      flush: 'post'
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    inputType: function (){
      if(this.type === 'number') return 'number';
      else return 'text';
    },
    showPlaceholder: function (){
      if(!this.placeholder) return false;
      if(this.value === null || this.value === undefined || this.value.toString() === '') return true;
      return false;
    }
  },
  methods:{
    onBlur(){
      this.$emit('blur', this.value)
    },
    onChange(evt){
      if (['english-number'].includes(this.type)){
        this.value = evt.target.value = evt.target.value.replace(/[^A-Za-z0-9]/g, '');
      }
    },
    onKeypress(evt){
      if (['english-number'].includes(this.type)){
        const englishAlphabetDigitsAndWhiteSpace = /[A-Za-z0-9]/g;
        if (!englishAlphabetDigitsAndWhiteSpace.test(evt.key)){
          evt.preventDefault();
          return false;
        }
      } else if (['text-number'].includes(this.type) && (evt.which < 48 || evt.which > 57)){
        evt.preventDefault();
      }

      if (this.type === 'float' &&
          (evt.which !== 46 ||
              (evt.which === 46 &&
                  (this.value === '' || this.value.toString().match(new RegExp(/\./)))
              )
          ) &&
          (evt.which < 48 || evt.which > 57))
      {
        evt.preventDefault();
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";
.base-input{
  display: flex;
  flex-direction: column;
  .base-input-control{
    &:-webkit-autofill {
      background-color: red;
      color: red;
    }
  }
  .base-input-label{
    margin-bottom: 8px;
    line-height: 14px;
  }
  .base-input-container{
    padding: 17px 16px;
    border: 1px solid white;
    border-radius: 8px;
  }
  .input-placeholder{
    color: $c-text-secondary;
  }
  .base-input-error{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    svg{
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

.mobile-device{

}

body.rtl {
  .base-input{
    .base-input-error{
      svg{
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}

</style>
