<template>
  <InvalidProperty v-if="!propsValidation.isValid" :message="propsValidation.message" />
  <template v-else>
    <div part="analysis-channel" class="analysis-channel" v-if="data">
      <div v-if="props.name" part="analysis-channel-title" class="analysis-channel-title">{{props.name}}</div>
      <div part="analysis-channel-diamond" class="analysis-channel-diamond">
        <Diamond part="nms-diamond" class="nms-diamond" :values="diamond"/>
      </div>
      <RiskBlocks v-if="data.risk" :risk="data.risk" :localeTexts="Locale.risk" />

      <PercentageLine :name="Locale.energy" :value="data.percentageLines.energy" />
      <PercentageLine :name="Locale.stress" :value="data.percentageLines.stress" />
      <PercentageLine :name="Locale.mentalEffort" :value="data.percentageLines.mentalEffort" />
      <PercentageLine :name="Locale.confidence" :value="data.percentageLines.confidence" />
      <PercentageLine :name="Locale.excitement" :value="data.percentageLines.excitement" />
      <PercentageLine :name="Locale.distress" :value="data.percentageLines.distress" />

      <CallTags :tags="data.callTags" :localeTexts="Locale"></CallTags>
    </div>
  </template>
</template>

<script>
const propsDefinition = {
  name: {
    required: false,
  },
  report: {
    required: true,
    typeof: "object",
    existProps: ['reports', 'segments']
  },
  channel:{
    required: false,
    typeof: "number",
    default: 0,
  },
  localeTexts: {
    required: false,
    typeof: "object",
    existProps: ['tags', 'energy', 'stress', 'mentalEffort', 'confidence', 'excitement', 'distress', 'risk', 'emotionDiamond'],
  },
  language: {
    required: false,
  }
}
</script>
<script setup>
import Diamond from "@/components/EmotionDiamondLibrary/blocks/Diamond.ce.vue";
import RiskBlocks from "./components/RiskBlocks.vue";
import PercentageLine from "./components/PercentageLine.vue";
import CallTags from "./components/CallTags.vue";

import InvalidProperty from '@/components/InvalidProperty.vue';
import {getDefinePropsObject, getLocaleTexts, validateProps} from "@/helpers/helper.js";
import {computed} from "vue";
import {diamondHeadersDictionary} from "@/utils/dictionary.js";
import {getRiskType, headerPos} from "@/utils/emlo.js";

const stringifyProps = defineProps(getDefinePropsObject(propsDefinition));

const {propsValidation, props} = validateProps(propsDefinition, stringifyProps);
const Locale = getLocaleTexts(props, (locale) => ({...locale.analysisReport, emotionDiamond: locale.emotionDiamond}));

const data = computed(() => {
  if(!props.value.report) return null;

  return channelAnalysis(props.value.report, props.value.channel);
})

const diamond = computed(() => JSON.stringify(data.value?.diamond));

function channelAnalysis(content, channel){
  const report = {
    diamond: [],
    percentageLines: {},
    callTags: [],
  };
  if(!content.segments || !content.reports || !content.segments.data) return report;

  const validSegments = content.segments.data.filter(s => s[headerPos(content, 'validSegment')] && s[headerPos(content, 'channel')] === channel);
  const channelReport = content.reports["channel-"+channel];

  if(!channelReport) return report;

  if(channelReport.edp){
    for (const dk in diamondHeadersDictionary) {
      const value = channelReport.edp[dk]
      report.diamond.push({
        text: Locale.value.emotionDiamond[diamondHeadersDictionary[dk]],
        value: value,
      })
    }
  }

  const LVAPos = headerPos(content, 'offlineLVA-value') || headerPos(content, 'onlineLVA');
  if(LVAPos) {
    report.risk = validSegments.reduce((res, curr) => {
      const riskType = getRiskType(curr[LVAPos]);
      if(riskType) res[riskType]++;
      return res;
    }, {high: 0, medium: 0, suspected: 0})
  }

  report.percentageLines = {};

  const ocaPos = headerPos(content, 'overallCognitiveActivity')
  const ocaAverage = validSegments.reduce((res, curr) => res + curr[ocaPos], 0) / (validSegments.length || 1);
  let ocaValue = (ocaAverage - 1100) / 5;
  if(ocaValue < 0) ocaValue = 0;
  if(ocaValue > 100) ocaValue = 100;

  report.percentageLines.distress = Math.round(ocaValue) || 0;

  function calcPercentageRound(value){
    let percent = Math.round(value);
    if(percent < 0) percent = 0;
    if(percent > 100) percent = 100;
    return percent
  }

  function calcPercentageLines(obj){
    return calcPercentageRound( ((obj?.highPercentage || 0) * 1.5) + (obj?.midPercentage || 0) + ((obj?.lowPercentage || 0) * 0.5) );
  }

  function calcPercentageExcitement(obj){
    return calcPercentageRound(((obj?.highPercentage || 0) * 1.2));
  }


  report.percentageLines.energy = channelReport.profile?.energy ? calcPercentageLines(channelReport.profile.energy) : 0;
  report.percentageLines.stress = channelReport.profile?.stress ? calcPercentageLines(channelReport.profile.stress) : 0;
  report.percentageLines.mentalEffort = channelReport.profile?.mentalEffort ? calcPercentageLines(channelReport.profile.mentalEffort) : 0;
  report.percentageLines.confidence = channelReport.edp && channelReport.edp['EDP-Confident'] ? Math.round(channelReport.edp['EDP-Confident'] || 0) : 0;
  report.percentageLines.excitement = channelReport.profile?.excitement ? calcPercentageExcitement(channelReport.profile.excitement) : 0;

  report.callTags = channelReport.tags || [];

  return report;
}

</script>

<style lang="scss" src="./AnalysisChannelReport.scss" />
