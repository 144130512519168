<template>
  <div :class="this.class">
    <div class="bt-pagination">
      <div class="bt-pagination-quick">
        <div class="pagination-btn mr-1 transform-180" @click="setPage(pageModel > 1 ? pageModel - 1 : 1)"><Icon name="arrow" /></div>
        <div class="pagination-btn mr-1"
             v-for="item in visiblePageBtns"
             @click="setPage(item.label)"
             :class="item.class + (pageModel === item.label ? ' pagination-btn-active' : '')">
          <div class="px-2">{{item.label}}</div>
        </div>
        <div class="pagination-btn" @click="setPage(pageModel < pageCount ? pageModel + 1 : pageCount)"><Icon name="arrow" /></div>
      </div>
      <div class="bt-pagination-sort-nav">
        <div class="d-flex align-items-center">
          {{t('common.goTo')}}
          <PaginationInput class="ml-1" v-model="pageModel"/>
        </div>
        <div class="d-flex align-items-center">
          {{t('common.showed')}} {{pageModel * rowPerPageModel > totalRows ? totalRows : pageModel * rowPerPageModel}}/{{totalRows}}
          <PaginationDropdown class="ml-1" v-model="rowPerPageModel" :options="rowPerPageOptions"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { computed, onMounted } from 'vue';
import {useI18n} from "vue-i18n";
import Icon from 'Common/components/svg';
import PaginationInput from 'Common/components/table/PaginationInput';
import PaginationDropdown from 'Common/components/table/PaginationDropdown';

export default {
  components: {Icon, PaginationInput, PaginationDropdown},
  name: 'BaseTablePagination',
  props: {
    totalRows: Number,
    dataFilter: Object, //{page, limit}
    countVisiblePageBtn: {
      type: Number,
      default: 5,
    },
    class: String,
    dropdownOptions: Array,
  },
  emits: ['update:dataFilter'],
  setup(props, {emit}){
    const { t } = useI18n({ useScope: 'global' });
    const _rowPerPageOptions = [
      {value: 100, label: t('common.rowsPerPage', {rows: 100}) },
      {value: 50, label: t('common.rowsPerPage', {rows: 50}) },
      {value: 20, label: t('common.rowsPerPage', {rows: 20}) },
      {value: 10, label: t('common.rowsPerPage', {rows: 10}) },
    ];

    const rowPerPageOptions = computed(() => {
      if(props.dropdownOptions) return props.dropdownOptions;
      else return _rowPerPageOptions;
    });

    const pageModel = computed({
      get() {
        return props.dataFilter.page;
      },
      set(value) {
        emit('update:dataFilter', {
          ...props.dataFilter,
          page: value
        });
      }
    });

    const rowPerPageModel = computed({
      get() {
        return props.dataFilter.limit;
      },
      set(value) {
        emit('update:dataFilter', {
          ...props.dataFilter,
          page: props.dataFilter.page || 1,
          limit: value
        });
      }
    });

    onMounted(() => {
      if(!props.dataFilter.limit) rowPerPageModel.value = 20;
      if (props.dataFilter.page) {
        setPage(props.dataFilter.page);
      }

    });

    const pageCount = computed(() => {
      return Math.ceil(props.totalRows / rowPerPageModel.value);
    });

    const visiblePageBtns = computed(() => {
      const btns = [];

      const lrPageCount = Math.floor(props.countVisiblePageBtn / 2);
      let startPos = 1;
      if(pageCount.value > props.countVisiblePageBtn && (pageModel.value - lrPageCount) > 1) {
        btns.push({
          class: 'pagination-dots',
          label: '...',
        })
        startPos = pageModel.value - lrPageCount;
      }
      startPos = pageModel.value + lrPageCount >= pageCount.value ? pageCount.value - props.countVisiblePageBtn + 1 : startPos;
      startPos = startPos >= 1 ? startPos : 1;
      for (let i = startPos; i < startPos + props.countVisiblePageBtn && i <= pageCount.value; i++){
        btns.push({
          class: 'pagination-btn',
          label: i
        });
      }
      if(pageCount.value > props.countVisiblePageBtn && (lrPageCount + pageModel.value) < pageCount.value){
        btns.push({
          class: 'pagination-dots',
          label: '...',
        })
      }

      return btns;
    });

    return {
      t,
      pageModel,
      rowPerPageModel,
      visiblePageBtns,
      pageCount,
      rowPerPageOptions,
      setPage
    }

    function setPage(newPage){
      if(newPage !== pageModel.value) pageModel.value = newPage;
    }
  }
}
</script>

<style scoped lang="scss">
@import 'Common/assets/styles/themes/light/light-theme-colors';

.bt-pagination{
  display: flex;
  gap: 24px;
}
.bt-pagination-quick{
  display: flex;
}
.bt-pagination-sort-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

  .pagination-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid #A3AED0;
    background: $c-white;
    border-radius: 6px;
    cursor: pointer;
    &.pagination-dots{
      pointer-events: none;
      border: none;
      font-size: 20px;
    }
  }
  .pagination-btn-active{
    color: #4E9BF0;
    border-color: #4E9BF0;
  }
  .transform-180{
    transform: rotateY(180deg);
  }

.mobile-device{
  @media (max-width: 767px){ //375 - 768
    .bt-pagination{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
    .bt-pagination-quick{
      display: flex;
    }
    .bt-pagination-sort-nav{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      gap: 12px;
    }
  }
}


body.rtl {
  
  .pagination-btn:not(.mr-1) {
    transform: rotateY(180deg);
  }
  
  .transform-180 {
    transform: rotateY(0deg);
  }

  
}
</style>
