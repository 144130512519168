<template>
  <div class="notifications">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>

    <AppPhoneStrip v-if="appStore.isMobile"/>

    <div class="base-tabs">
      <div class="base-tabs-header base-tabs-header--dark">
        <div
            v-for="tab in tabs"
            class="base-tab"
            :class="{'base-tab-active': activeTab === tab.value}"
            :key="tab.value"
            @click="setActiveTab(tab.value)"
        >
          <span class="mr-2">
            <Icon class="svg" :class="tab.iconClass" :name="tab.icon"/>
          </span>
          {{ t(`common.${tab.value}`) }}
          <div class="notifications-tab-count">{{tab.count}}</div>
        </div>
        <div class="ms-auto me-4 c-secondary text-nowrap overflow-hidden" v-if="tabInfo">{{tabInfo}}</div>
      </div>

      <div class="base-tab-content borderless">
        <BaseTable async-data
                   :headers="grid_heads"
                   :rows="filteredData"
                   v-model:data-filter="dataFilter"
                   v-model:data-params="dataParams"
                   class="notifications-table"
                   @loadData="loadData"
                   pagination
                   :pagination-total-rows="totalRows"
        >
          <template #header._check="{check, setCheck}">
            <th class="notifications-table-read small-col">
              <BaseCheckbox :model-value="check" @update:modelValue="setCheck" />
            </th>
          </template>
          <template #_check="{row, check, setCheck}">
            <td :class="row.isRead ? 'notifications-table-read' : 'notifications-table-unread'">
              <div class="d-flex mt-1"><BaseCheckbox :model-value="check" @update:modelValue="setCheck" /></div>
            </td>
          </template>

          <template #header.groupCode="{header}">
            <th>
              <div class="base-table-th">
                <div :key="item.value" v-for="item in sortOptions" class="quick-action-popper cursor-pointer">
                  <Popper class="d-flex" arrow hover placement="top">
                    <div class="notifications-tf-container mr-1"
                         :class="{'notifications-tf-active': activeSortByGroupName === item.value}"
                         @click="sortNotificationsByGroupName(item)"
                    >
                      <div class="notifications-tf"
                           :class="{'notifications-tf-all': item.value === ''}"
                      >
                        <div v-if="item.value === ''">{{t('common.all')}}</div>
                        <Icon v-else :name="item.icon" />
                      </div>
                    </div>
                    <template #content>
                      <div class="notifications-tf-name">{{ item.label }}</div>
                    </template>
                  </Popper>
                </div>
              </div>
            </th>
          </template>


          <template #header.actions="{header}" v-if="canEdit">
            <th class="small-col">
              <div class="actions">
                <template v-if="canEdit">
                  <Popper arrow hover placement="top">
                    <div class="base-table-th notifications-header-icon">
                      <Icon v-if="activeTab !== 'archive'" name="archive-action" />
                      <Icon v-else name="unarchive" />
                    </div>
                    <template #content>
                      <div v-if="activeTab !== 'archive'">{{ t('notificationList.hint_archive') }}</div>
                      <div v-else>{{ t('notificationList.hint_unarchive') }}</div>
                    </template>
                  </Popper>

                  <Popper arrow hover placement="top">
                    <div class="base-table-th notifications-header-icon">
                      <Icon v-if="activeTab !== 'trash'" name="bin" />
                      <Icon v-else name="untrash" />
                    </div>
                    <template #content>
                      <div v-if="activeTab !== 'trash'">{{ t('notificationList.hint_trash') }}</div>
                      <div v-else>{{ t('notificationList.hint_untrash') }}</div>
                    </template>
                  </Popper>
                </template>

                <Popper arrow hover placement="top">
                  <div class="base-table-th notifications-header-icon">
                    <Icon name="eye-2" />
                  </div>
                  <template #content>
                    <div>{{ t('notificationList.hint_see') }}</div>
                  </template>
                </Popper>
              </div>

            </th>
          </template>


          <template #groupCode="{row}">
            <div class="d-flex flex-row align-items-center">
              <div class="notifications-star-action" :style="{visibility: activeTab === 'trash' ? 'hidden' : ''}" @click="markAs(notificationStore.markAsStarred(row.notificationExternalId))">
                <span v-if="row.isStarred"><Icon class="svg" name="star-filled"/></span>
                <span v-else><Icon class="svg" name="star"/></span>
              </div>
              <div class="d-flex flex-row align-items-center">
                <div class="notifications-tf-badge" :style="{'background-color': sortOptions.find((i) => i.value === row.groupName)?.color || row.color}"></div>
                <div>{{row.groupCode}}</div>
              </div>
            </div>
          </template>
          <template #text="{row}">
            <div class="notifications-text mt-1" @click="row.isActive ? closeItem() : setActive(row.notificationExternalId)" v-html="row.text"></div>
          </template>
          <template #createdDate="{row}">
            <div class="mt-1 text-nowrap">{{getFormattedDateTime(row.createdDate)}}</div>
          </template>
          <template #actions="{row}" >
            <div  class="actions">
              <template v-if="canEdit">
                <Popper v-if="activeTab === 'starred' ? !row.isArchived : activeTab !== 'archive'" :style="{visibility: activeTab === 'trash' ? 'hidden' : ''}" arrow hover placement="top">
                  <div class="notifications-action" @click="markAs(notificationStore.markAsArchive(row.notificationExternalId))"><Icon name="archive-action" /></div>
                  <template #content><div>{{ t('notificationList.hint_archive') }}</div></template>
                </Popper>
                <Popper v-else arrow hover placement="top">
                  <div class="notifications-action" @click="markAs(notificationStore.markAsUnArchive(row.notificationExternalId))">
                    <Icon name="unarchive" />
                  </div>
                  <template #content><div>{{ t('notificationList.hint_unarchive') }}</div></template>
                </Popper>

                <Popper v-if="activeTab !== 'trash'" arrow hover placement="top">
                  <div class="notifications-action" @click="markAs(notificationStore.markAsTrash(row.notificationExternalId))"><Icon name="bin" /></div>
                  <template #content><div>{{ t('notificationList.hint_trash') }}</div></template>
                </Popper>
                <Popper v-else arrow hover placement="top">
                  <div class="notifications-action" @click="markAs(notificationStore.markAsUnTrash(row.notificationExternalId))"><Icon name="untrash" /></div>
                  <template #content><div>{{ t('notificationList.hint_untrash') }}</div></template>
                </Popper>
              </template>
              <Popper v-if="!row.isActive" arrow hover placement="top">
                <div class="notifications-action" @click="setActive(row.notificationExternalId)"><Icon name="eye-2" /></div>
                <template #content><div>{{ t('notificationList.hint_see') }}</div></template>
              </Popper>
              <Popper v-else arrow hover placement="top">
                <div class="notifications-action" @click="closeItem()"><Icon name="eye-close-2" /></div>
                <template #content><div>{{ t('notificationList.hint_unsee') }}</div></template>
              </Popper>
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "Common/components/controls/breadcrumbs";
import AppPhoneStrip from "@/components/AppPhoneStrip";
import {ref, onMounted, computed, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useNotificationStore} from "Common/stores/notificationStore";
import Icon from "Common/components/svg";
import BaseCheckbox from "Common/components/controls/BaseCheckbox";
import BaseTable from "Common/components/table/BaseTable";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import {useAppStore} from "Common/stores/appStore";

export default {
  components: {
    Breadcrumbs,
    Icon,
    BaseCheckbox,
    BaseTable,
    AppPhoneStrip
  },
  name: 'NotificationList',
  props: {},
  computed: {
    tabInfo() {
      return this.tabs.find(i => i.value === this.activeTab)?.info
    },
    canEdit() {

      const permissions = this.userStore.user?.permissions;

      if (!permissions.notifications_edit) {
        return false;
      }

      if (!this.organizationStore.isOwner) {
        return permissions.handle_other_user_data;
      }

      return true;
    }
  },
  setup(props) {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const organizationStore = useOrganizationStore();

    const notificationStore = useNotificationStore();
    const { t, locale } = useI18n({ useScope: 'global' });
    const tabs = ref([
        {value: 'all', icon: 'notifications', iconClass: "str", filter: {isTrashed: false, isArchived: false, isStarred: undefined, isRead: undefined}, count: computed( () => notificationStore.notificationStats.allCount)},
        {value: 'unread', icon: 'mail-message', iconClass: "fil", filter: {isTrashed: undefined, isArchived: undefined, isStarred: undefined, isRead: false}, count: computed( () => notificationStore.notificationStats.unreadCount)},
        {value: 'archive', icon: 'archive', iconClass: "str", filter: {isTrashed: false, isArchived: true, isStarred: undefined, isRead: undefined}, count: computed( () => notificationStore.notificationStats.archivedCount)},
        {value: 'trash', icon: 'trash', info: t("notificationList.hint_trashed_items_lifetime"), iconClass: "str", filter: {isTrashed: true, isArchived: undefined, isStarred: undefined, isRead: undefined}, count: computed( () => notificationStore.notificationStats.trashedCount)},
        {value: 'starred', icon: 'star', iconClass: "str", filter: {isTrashed: false, isArchived: undefined, isStarred: true, isRead: undefined}, count: computed( () => notificationStore.notificationStats.starredCount)},
    ]);
    const activeTab = ref('all');
    const sortOptions = ref([
        {value: '', label: t('common.all'), color: 'inherit'},
        {value: 'user', label: t('common.user'), color: '#4E9BF0', icon: 'user_2'},
        {value: 'billing', label: t('common.billing'), color: '#FF618D', icon: 'billing_2'},
        {value: 'site', label: t('common.site'), color: '#FFC700', icon: 'site'},
        ]
    );

    const activeSortByGroupName = ref('');
    const breadcrumbs = ref([
      {
        "name": t('common.notifications'),
        "link": "#",
        "active": true
      }
    ]);
    const grid_heads = computed(() => [
      {sortable: false, label:  '', fieldName: 'groupCode'},
      {sortable: false, label: t('common.title'), fieldName: 'text'},
      {sortable: true, label: t('common.date'), fieldName: 'createdDate'},
      {sortable: false, label: '', fieldName: 'actions'},
    ]);

    const dataFilter = ref({...tabs.value.find((tab) => tab.value === activeTab.value).filter});
    const dataParams = ref({orderBy: undefined}); //{fieldName: '', rule: 'asc'}
    const totalRows = ref(0);
    const afterLoadData = ref({func: null});

    watch(() => notificationStore.openNotification.openExternalId, () => {
      if(notificationStore.openNotification.openExternalId){
        const openExternalId = notificationStore.openNotification.openExternalId;
        setActiveTab('unread');
        afterLoadData.value = {
          func: () => setActive(openExternalId),
        }
        notificationStore.openNotification.openExternalId = null;
      }
    }, {immediate: true});

    const filteredData = computed(() => {
        return notificationStore.notifications.map( item => {
          item['_class'] = {'notifications-item': true, 'new': !item.isRead, 'active': item.isActive};
          return item;
        });
    });

    return {
      appStore,
      userStore,
      organizationStore,
      t,
      locale,
      breadcrumbs,
      grid_heads,
      notificationStore,
      tabs,
      activeTab,
      sortOptions,
      activeSortByGroupName,

      filteredData,
      dataFilter,
      dataParams,
      totalRows,

      sortNotificationsByGroupName,
      setActiveTab,
      setActive,
      closeItem,
      archiveSelected,
      trashSelected,
      starredSelected,
      getFormattedDateTime,
      loadData,
      markAs,
      canEditPermission,
    };

    function loadData(filter, params, isLoadStats = false) {
      notificationStore.loadNotifications(filter, params).then((data) => {
        totalRows.value = data.total;
        if(isLoadStats) {
          notificationStore.loadNotificationStats().catch((err) => {
            console.log(err);
          });
        }
        if(afterLoadData.value.func) {
          afterLoadData.value.func();
          afterLoadData.value.func = null;
        }
      }).catch((err) => {
        console.log(err);
      });
    }

    function sortNotificationsByGroupName(notificationType) {
      activeSortByGroupName.value = notificationType.value;
      dataFilter.value = {
        ...dataFilter.value,
        groupName: notificationType.value === '' ? undefined : notificationType.value,
        page: 1,
      };
    }

    function canEditPermission() {
      const permissions = userStore.user.permissions;

      if (!permissions.notifications_edit) {
        return false;
      }

      if (!organizationStore.isOwner) {
        return permissions.handle_other_user_data;
      }

      return true;
    }

    function setActiveTab(tab) {
      activeTab.value = tab;
      dataFilter.value = {
        ...dataFilter.value,
        ...tabs.value.find((tab) => tab.value === activeTab.value).filter,
        page: 1,
      };
    }

    function setActive(id) {

      const canEdit = canEditPermission();
      notificationStore.setActive(id, 'isActive', true, canEdit);
    }

    function closeItem() {

      const canEdit = canEditPermission();
      notificationStore.setActive(false, 'isActive', false, canEdit);
    }

    function markAs(promise) {
      promise.then(() => {
        loadData(dataFilter.value, dataParams.value, true);
      });
    }

    function archiveSelected() {
      filteredData.value.forEach((notify) => {
        if(notify['_checked']) notificationStore.markAsArchive(notify.notificationExternalId);
      });
    }

    function trashSelected() {
      filteredData.value.forEach((notify) => {
        if(notify['_checked']) notificationStore.markAsTrash(notify.notificationExternalId);
      });
    }

    function starredSelected() {
      filteredData.value.forEach((notify) => {
        if(notify['_checked']) notificationStore.markAsStarred(notify.notificationExternalId);
      });
    }

    function getFormattedDateTime(date){
      let dateSrt = Intl.DateTimeFormat('en-AU', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', }).format(Date.parse(date))
      dateSrt = dateSrt.replace(', ', ' at ');
      let splitted = dateSrt.split(' ');
      splitted[splitted.length - 1] = splitted[splitted.length - 1].toUpperCase();
      return splitted.join(' ');
    }
  }
}
</script>

<style scoped lang="scss">
  .notifications .base-tab-content{
    background-color: white;
    padding: 0;
  }
  .notifications:deep(.base-table){
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .quick-action-popper > *{
    border: unset !important;
    margin: 0 !important;

  }
  .notifications-tab-count{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    min-height: 18px;
    height: 18px;
    border-radius: 9px;
    padding: 0 5px;
    margin-left: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
  }
  .notifications-tf-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    & + .notifications-tf-container{
      margin-left: 12px;
    }
  }
  .notifications-tf-active{
    border: 1px solid white;
    border-radius: 50%;
  }
  .notifications-tf{
    cursor: pointer;
    svg{
      width: 20px;
      height: 20px;
    }
    border-radius: 50%;
  }
  .notifications-tf-name{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .notifications-tf-all{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .notifications-tf-badge{
    min-width: 6px;
    min-height: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
  }
  .notifications-header-icon{
    //themes
  }
  .notifications-action{
    cursor: pointer;
  }
  .notifications-star-action{
    min-height: 24px;
    min-width: 24px;
    margin-right: 13px;
    margin-left: 2px;
    cursor: pointer;
  }
  .notifications-text {
    width: 100%;
    max-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notifications-item.active {
    .notifications-text {
      overflow: visible;
      -webkit-line-clamp: unset;
      max-height: unset;
    }
  }
  .notifications-table:deep(td){
      vertical-align: top;
  }
  .notifications-table-read{
    border-left: 4px solid transparent !important;
  }
  .notifications-table-unread{
    border-left: 4px solid;
  }

body.rtl {
  .notifications-tab-count{
    margin-right: 6px;
    margin-left: 0;
  }
  .notifications-tf-container{
    & + .notifications-tf-container{
      margin-right: 12px;
      margin-left: 0;
    }
  }
  .notifications-tf-badge{
    margin-left: 6px;
    margin-right: 0;
  }
  .notifications-star-action{
    margin-left: 13px;
    margin-right: 2px;
  }
  .notifications-table-read{
    border-right: 4px solid transparent !important;
    border-left: unset !important;
  }
  .notifications-table-unread{
    border-right: 4px solid;
    border-left: unset;
  }
}
</style>
<style lang="scss">
.notifications {
  .actions.actions.actions {
    display: flex;
    &>div {
      margin-right:0 !important;
    }
  }
}

body.rtl {
  .notifications {
    .actions.actions.actions {
      &>div {
        margin-left:0 !important;
        margin-right:0 !important;
      }
    }
  }
}
</style>
