<template>
  <div>
    <Breadcrumbs :items="breadcrumbData"/>
    <CustomerSupportCases :is-admin="isCustomerSupport" @openCase="openCase"/>
  </div>
</template>

<script setup>
import Breadcrumbs from "Common/components/controls/breadcrumbs";
import CustomerSupportCases from "./components/CustomerSupportCases";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useI18n} from "vue-i18n";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {useUserStore} from "Common/stores/userStore";
import {useCustomerSupportStore} from "Common/stores/customerSupportStore";
import {computed, onMounted} from "vue";

const userStore = useUserStore();
const csStore = useCustomerSupportStore()
const organizationStore = useOrganizationStore();
const {t} = useI18n({useScope: 'global'});

const breadcrumbData = [{
  "name": t('common.customerSupport'),
  "link": "#",
  "active": false
}];

const isCustomerSupport = computed(() => {
  return !!userStore.user?.permissions?.cs_manage_write
})


function openCase(csSupportRequestExternalId) {
  router.push({
    name: ROUTES.CustomerSupportCaseManage,
    params: {csSupportRequestExternalId: csSupportRequestExternalId}
  });
}

onMounted(() => {
  if(isCustomerSupport.value) {
    csStore.searchCustomerSupports()
    csStore.getCustomerSupportStats()
  }
})

</script>
