<template>
  <div class="base-table-container"  
       :class="{'base-table-sticky-header': stickyHeader, 'translation-table-scrolled': tableScrollPosition > 0}"
       @scroll="onScrollTable">
    <div class="base-table">
      <slot
          name="header.topActions"
          :rowsData="rowsData"
      ></slot>
      <table>
        <thead>
        <tr :style="trStyle">
          <slot v-if="checkable" name="header._check" :check="checkAll" :setCheck="(value) => {this.checkAll = value}">
            <th class="small-col">
              <BaseCheckbox v-model="checkAll"/>
            </th>
          </slot>
          <template :key="header" v-for="header in headers.filter(h => h.desktop?.visible !== false)">
            <slot :name="'header.'+header.fieldName" :header="header">
              <th :class="header.class">
                <div class="base-table-th">
                  <div v-if="header.sortable" @click="changeColumnSort(header.fieldName)">
                    <div class="filter-icon" v-if="getFieldOrderByName(header.fieldName).rule === 'desc'">
                      <Icon name="sort-desc"/>
                    </div>
                    <div class="filter-icon" v-else>
                      <Icon name="sort-asc"/>
                    </div>
                  </div>
                  {{ header.label }}
                </div>
              </th>
            </slot>
          </template>
        </tr>
        </thead>
        <tbody class="position-relative">

        <BasePreloader :show="loading"/>
        <tr v-if="loading && !rowsData?.length" class="base-table-fake-row">
          <td></td>
        </tr>
        <tr v-if="!loading && !rowsData?.length" class="base-table-fake-row">
          <td :colspan="headers.length">
            <slot name="noData"></slot>
          </td>
        </tr>

        <tr :key="key" v-for="(row, key) in rowsData" @click="$emit('rowClicked', {key, row})" :style="row['_style']"
            :class="row['_class']">
          <slot v-if="checkable" name="_check" :row="row" :check="row[checkableField]"
                :setCheck="(value) => {row[this.checkableField] = value}">
            <td>
              <BaseCheckbox v-model="row[this.checkableField]"/>
            </td>
          </slot>

          <td v-for="header in headers.filter(h => h.desktop?.visible !== false)" :class="{
                      'filterable': header.sortable && !header.comparison,
                      [header.rowClass]: header.rowClass,
                    }">
            <slot :name="header.fieldName" :row="row">
              <div :class="{
                'bt-td-container': true, 
                'comparison-container': header.comparison, 
                'comparison-color': header.comparison && header.comparison.useComparisonColor,
                [header.comparison ? getComparisonData(row, header).type : '']: true
              }">
                <div v-if="header.comparison" class="filter-icon"
                     :class="{[getComparisonData(row, header).direction]: true, [getComparisonData(row, header).type]: true}">
                  <Icon v-if="getComparisonData(row, header).compareIconName"
                        :name="getComparisonData(row, header).compareIconName"/>
                  <div v-else style="width: 24px; height: 24px;"></div>
                </div>
                {{ row[header.fieldName] }}
              </div>
            </slot>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <BaseTablePagination v-if="pagination" class="my-3" 
                         :total-rows="paginationTotalRows || (rows || []).length"
                         v-model:data-filter="filter"/>
  </div>
</template>

<script setup>
import Icon from "Common/components/svg";
import BaseTablePagination from "Common/components/table/BaseTablePagination";
import BaseCheckbox from "Common/components/controls/BaseCheckbox";
import BasePreloader from "Common/components/controls/BasePreloader";

import {init, propsDefinitions, emitDefinitions} from "Common/components/table/baseTable/BaseTableController";
import {ref} from "vue";

const props = defineProps(propsDefinitions);
const emit = defineEmits(emitDefinitions);

const {
  rowsData,
  rowsDataForExport,
  filter,
  checkAll,
  filterParams,
  isSomeRowChecked,
  changeColumnSort,
  getFieldOrderByName,
  getComparisonData,
  stickyHeader,
} = init(props, emit);

const tableScrollPosition = ref(0);
function onScrollTable(e){
  if(stickyHeader.value) tableScrollPosition.value = e.target.scrollTop;
}

</script>
<style scoped lang="scss">

.base-table-sticky-header{
  max-height: 500px;
  overflow: auto;
  &.translation-table-scrolled{
    .base-table{
      thead th{
        border-radius: 0;
      }
    }
  }
  .base-table{
    overflow: unset;
    thead th{
      position: sticky;
      top: 0;
    }
  }
}
</style>
