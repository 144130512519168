import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from 'Common/environments/environment';
import {useUserStore} from './userStore';
import dayjs from "dayjs";
import {camelize} from "Common/utility/formatUtility";

const defaultOrganizationLogo = require('../assets/default_company_logo.png');

export const useOrganizationStore = defineStore('organizationStore', {
    state: () => {
        return {
            orgs: {},
            organizations: [],
            distributorsOrganizations: [],
            userOrgExternalId: null,
            currentOrgExternalId: null,

            showPhoneVerificationModal: false,
            verificationPhoneNumber: '',
            
            checkDistributorModals: false,
        }
    },
    getters: {
        organization: (state) => {
            return state.orgs[state.currentOrgExternalId || state.userOrgExternalId] || {}
        },
        userOrg: (state) => {
            return state.orgs[state.userOrgExternalId] || {}
        },
        isOwner: (state) => {
            return state.currentOrgExternalId === null;
        },
        isPhoneVerified: (state) => {
            return state.organization && !!state.organization?.phoneVerified
        },
        canVerify: (state) => {
            const us = useUserStore();
            let canVerify = false;
            if(Array.isArray(us.user.roles) && us.user.roles.includes('organization_admin')){
                canVerify = true;
            }
            if(state.organization.ownerUserExternalId === us.user.externalId) {
                canVerify = true;
            }

            return canVerify;
        }
    },
    actions: {
        updateSecuritySettings(data){
            return axios.post(environment.organizationsLocalServiceUrl + '/security/updateSecuritySettings', {data: data})
                .then((response) => {
                    if(!response.data.success) return Promise.reject(response.data);
                    return response.data.data || "";
                });
        },
        getSettings(){
            return axios.post(environment.organizationsLocalServiceUrl + '/security/getSettings')
                .then((response) => {
                    if(!response.data.success) return Promise.reject(response.data);
                    return response.data.data || "";
                });
        },
        changeDebtLimit(data){
            return axios.post(environment.organizationsLocalServiceUrl + '/organization/changeDebtLimit', {data: data})
                .then((response) => {
                    if(!response.data.success) return Promise.reject(response.data);
                    return response.data.data || "";
                });
        },
        checkAndShowPhoneVerificationModal(){
            if(this.canVerify){
                this.showPhoneVerificationModal = !this.isPhoneVerified;

                const reg = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4,6}$', 'im');
                if(reg.test(this.organization?.phoneNumber)) this.verificationPhoneNumber = this.organization?.phoneNumber;
                else this.verificationPhoneNumber = '';
            }
        },
        async setUserOrgExternalId(id){
            this.userOrgExternalId = id;
            return true;
        },
        setCurrentOrgExternalId(id){
            this.currentOrgExternalId = id;
            if(id && !this.orgs[id]) this.loadOrganizationById(id);
        },
        getAccountTypeForImg(organization = this.organization){
            if (!organization?.accountType?.name) return 'dev';

            let name = camelize(organization.accountType.name);
            name = name === 'development' ? 'dev' : name;
            return name;
        },

        loadOrganizationById(organizationExternalId, force = false){
            const that = this;
            if (this.organization.organizationExternalId === organizationExternalId && !force)
                return Promise.resolve(this.organization);

            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/getByExternalId`,{
                data:{
                    organizationExternalId
                }
            })
                .then((response) => {
                    if (!response.data.success) throw new Error(response.data.message);
                    that.orgs[organizationExternalId] = response.data.data || {}
                    that.orgs[organizationExternalId].imageLarge = that.orgs[organizationExternalId].imageLarge || defaultOrganizationLogo;
                    return that.orgs[organizationExternalId];
                })
        },

        getGiftsHistory(){
            return axios.post(`${environment.organizationsLocalServiceUrl}/organization/getGiftsHistory`,{})
                .then((response) => {
                    if (!response.data.success) throw new Error(response.data.message);
                    return response.data.data;
                })
        },

        updateDiscount(data){
            return axios.post(`${environment.organizationsLocalServiceUrl}/organization/updateDiscount`,{
                data
            })
                .then((response) => {
                    if (!response.data.success) throw new Error(response.data.message);
                    return response.data.data;
                })
        },

        updateOrganizationImage(organizationExternalId, file) {
            const url = `${environment.imagesUrl}/organization/uploadOrganizationImage`;
            const fd = new FormData();
            fd.append('organizationExternalId', organizationExternalId)
            fd.append('file', file)
            fd.append('create3Sizes', true)

            return axios.post(url, fd, {headers: { "Content-Type": "multipart/form-data" },}).then((res) => {
                if (!res.data.success) {
                    return Promise.reject(res.data);
                }

                const links = res.data.data.links;

                this.organization.imageSmall = links.small;
                this.organization.imageMedium = links.medium;
                this.organization.imageLarge = links.large;
            });
        },


        loadOrganizations(filter, params, noCache = false) {
            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/search`,{
                data:{
                    filter,
                    params
                }
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                if (!noCache) {
                    this.organizations = response.data.data.items || [];
                }
                return response.data.data;
            });
        },
        loadDistributorOrganizations(filter, params, noCache = false) {
            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/search`,{
                data:{
                    filter,
                    params
                }
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                if (!noCache) {
                    this.distributorsOrganizations = response.data.data.items || [];
                }
                return response.data.data;
            });
        },
        getCsvData() {
            return axios.post(`${environment.organizationsLocalServiceUrl}/organization/getCsvData`, {responseType: 'blob'})
        },
        getCsvData2() {
            return axios.post(`${environment.organizationsLocalServiceUrl}/organization/getCsvData2`, {responseType: 'blob'})
        },
        getCsvData3() {
            return axios.post(`${environment.organizationsLocalServiceUrl}/organization/getCsvData3`, {responseType: 'blob'})
        },

        updateOrganization(query) {

            const requestData = [
                "organizationExternalId",
                "org",
                "name",
                "phoneNumber",
                "mainContactEmail",
                "email",
                "defaultSender",
                "defaultLanguage",
                "sendEmails",
                "zipCode",
                "city",
                "stateProvince",
                "streetAddress",
                "countryCode",
                "vatId",
                "webPage",
                "emailInvoices",
                "emailNotifications",
                "billingUseCompanyAddress",
                "billingCountryCode",
                "billingStateProvince",
                "billingStreetAddress",
                "billingCity",
                "billingZipCode",
                "billingEmail",
                "dateFormat",
                "paypalAccountId",
                "defaultLanguage",
                "defaultHomeUrl"
            ].reduce((a,v) => Object.assign(a, typeof query[v] === "undefined" ? {} : {[v]: query[v]}), {});

            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/update`, {
                data: requestData
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);

                if(this.organization.organizationExternalId === requestData.organizationExternalId)
                    Object.assign(this.organization, requestData);

                let org = this.getOrganizationById(requestData.organizationExternalId);
                if(org) Object.assign(org, requestData);
            });
        },

        updateOrganizationConfiguration(data) {
            const urls = [
                `${environment.organizationsManagementServiceUrl}/organization/updateOrganizationConfiguration`,
                `${environment.organizationsLocalServiceUrl}/organization/updateOrganizationConfiguration`,
            ];

            return Promise.all(urls.map(url => axios.post(url, {
                data
            }))).then(responses => {
                responses.forEach(response => {
                    if (!response.data.success) return Promise.reject(response.data);
                })

                const org = this.getOrganizationById(data.organizationExternalId);
                if (org) Object.assign(org, data);
            })
        },

        getFreeCreditsAmount() {
            return axios.post(`${environment.organizationsLocalServiceUrl}/organization/getInitialBalance`)
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return response.data.data;
                });
        },

        getOrganizationById(organizationExternalId) {
            return this.organizations.find((o) => o.organizationExternalId === organizationExternalId);
        },

        deactivateOrganizationAccount(data) {
            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/deactivateAccount`, {
                data
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data;
            });
        },

        sendDeactivationCode() {
            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/sendDeactivationCode`, {

            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data;
            });
        },

        reactivateOrganizationAccount(email, code) {
            return axios.post(`${environment.organizationsManagementServiceUrl}/organization/reactivateAccount`, {
                data: {email, code}
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data;
            });
        },

        getOrganizationEmptyFields(org) {
            org = org || {};

            const requiredFields = [];

            // make sure all required fields are filled
            return requiredFields.filter(f => {
                const v = org[f];
                return !(v || "").trim();
            });
        },

        sendPhoneVerification(phoneNumber) {
            return axios.post(environment.organizationsLocalServiceUrl + '/organization/sendPhoneVerification', {
                data: { phoneNumber }
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        checkPhoneVerification(data) {
            return axios.post(environment.organizationsLocalServiceUrl + '/organization/checkPhoneVerification', {
                data: data
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },
        crmSearch() {
            return axios.post(environment.organizationsManagementServiceUrl + '/crm/search', {
                data: {}
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data?.data || [];
            });
        },
        crmInsert(data) {
            return axios.post(environment.organizationsManagementServiceUrl + '/crm/insert', {
                data: data
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        getOrganizationDiscountInfo(org, t) {

            let orgDisc = org?.discountPercent || 0;
            let discAt = org?.accountTypeDiscountPercent || 0;
            let date = org?.discountUntilDate
            if(dayjs(org?.discountUntilDate).isBefore(dayjs())) {
                orgDisc = 0;
                date = null;
            }

            let totalDiscountPercent = orgDisc + discAt;

            if (totalDiscountPercent > 100) {
                totalDiscountPercent = 100;
            }

            const discountText = totalDiscountPercent > 0 ? `-${totalDiscountPercent}%` : t('ati.noDiscount');

            return {
                accountTypeName: org?.accountTypeName,
                accountTypeDiscountPercent: discAt,
                discountPercent: orgDisc,
                discountUntilDate: date,
                totalDiscountPercent,
                discountText,
            }
        }
    }
})
