<template>
  <div class="base-input">
    <div v-if="name" class="d-flex flex-row justify-content-between">
      <div class="base-input-label">
        {{ name }}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div>
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <label class="base-input-container textarea" :class="{'base-input-disabled': disabled, 'base-input-money': money}">
      <span v-if="showPlaceholder"
            class="input-placeholder">
        {{placeholder}}
      </span>
      <span v-if="money">$</span>
      <textarea
          v-model="value"
          ref="textareaRef"
          :rows="rows"
          class="base-input-control"
          :disabled="disabled"
          @blur="onBlur"
          v-bind="attrs"
          :type="type"/>
      <template v-if="slots.placeholder">
        <slot name="placeholder" />
      </template>
      <span v-if="maxLength > 0" class="base-input-character-length">
        {{ charCounter }}/{{maxLength}}
      </span>
      <span v-if="isResizable" class="base-textarea-resize" @mousedown="onMouseDown" @touchstart="onMouseDown">
        <IconResize />
      </span>
    </label>
    <div v-if="error" class="base-input-error">
      <IconError/>
      <div v-html="error"></div>
    </div>
  </div>
</template>

<script setup>

import IconError from 'Common/assets/icons/error.svg?inline';
import IconResize from 'Common/assets/icons/resize.svg?inline';
import {computed, watch, useSlots, ref, onMounted, onUnmounted} from "vue";

const props = defineProps({
  name: String,
  isRequired: {
    type: Boolean,
    default: false
  },
  isResizable: {
    type: Boolean,
    default: false
  },
  disabled: {type: Boolean, required: false, default: false},
  modelValue: String,
  placeholder: [String, Boolean],
  money: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: Number,
    default: 2,
  },
  error: String,
  type: {type: String, default: "text"},
  maxLength: {type: Number, default: 0},
  attrs: {type: Object, required: false, default: {}},
});
const slots = useSlots();
const emit = defineEmits(['update:modelValue', 'blur']);

const textareaRef = ref(null);

const value = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit('update:modelValue', v)
  }
});

watch(value, (txt) => {
  if (props.maxLength > 0 && txt?.length > props.maxLength) {
    value.value = txt.substring(0, props.maxLength);
  }
}, {
  flush: "post"
});

const charCounter = computed(() => {
  if (!props.maxLength) return 0;
  return (value.value?.length || 0);
})

const showPlaceholder = computed(() => {
  if(!props.placeholder) return false;
  if(value.value === null || value.value === undefined || value.value.toString() === '') return true;
  return false;
})


function onBlur() {
  emit('blur', value.value)
}

let resizePosition = null;
onMounted(() => {
  document.body.addEventListener('mousemove', onResize);
  document.body.addEventListener('touchmove', onResize);
  document.body.addEventListener('mouseup', onMouseUp);
  document.body.addEventListener('touchend', onMouseUp);
})
onUnmounted(() => {
  document.body.removeEventListener('mousemove', onResize);
  document.body.removeEventListener('touchmove', onResize);
  document.body.removeEventListener('mouseup', onMouseUp);
  document.body.removeEventListener('touchend', onMouseUp);
})
function onMouseDown(e){
  resizePosition = e;
}
function onMouseUp(e){
  resizePosition = null;
}
function onResize(e){
  if(resizePosition) {
    if(e.type !== 'touchmove') {
      e.preventDefault();
      textareaRef.value.style.height = resizePosition.clientY - textareaRef.value.getBoundingClientRect().top + 'px';
    }
    else{
      textareaRef.value.style.height = e.changedTouches[0].clientY - textareaRef.value.getBoundingClientRect().top + 'px';
    }
    resizePosition = e;
  }
}

</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";

.base-input {
  display: flex;
  flex-direction: column;

  .base-input-label {
    margin-bottom: 8px;
  }

  .base-input-container {
    position: relative;
    padding: 17px 24px 24px 17px; //$search bta-00001
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    textarea {
      min-height: 24px;
      resize: none;
    }

    .base-input-character-length{
      position: absolute;
      bottom: 8px;
      font-size: 10px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: -0.02em;
      color: $c-text-secondary;
    }
    .base-textarea-resize{
      cursor: n-resize;
      position: absolute;
      bottom: 8px;
      right: 8px;
      color: $c-text-secondary;
      width: 12px;
      height: 12px;
      line-height: 0;
      touch-action: none;
    }
  }

  .input-placeholder{
    top: 17px; //$search bta-00001    the same as top padding
    color: $c-text-secondary;
  }



  .base-input-disabled {
    pointer-events: none;

    .base-input-control {
      background: #F6F6F8;
    }
  }

  .base-input-error {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    svg {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

.mobile-device{
  .base-input {
    .base-input-container {
      border-radius: 6px;
      height: unset;
      padding: 8px 16px 20px 16px; //$search bta-00001

      .input-placeholder{
        top: 8px; //$search bta-00001    the same as top padding
        line-height: 21px;
      }
    }
  }
}

body.rtl {
  .base-input {
    .base-input-container {
      padding: 17px 17px 24px 24px; 

      .base-textarea-resize{
        left: 8px;
        right: unset;
      }
    }

    
    .base-input-error {
      svg {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}
</style>
