<template>
  <div class="language-selector">
    <div @click.prevent.stop="open">
      <div class="languages--icon-container">
        <img :src="flagLink" />
      </div>
    </div>

    <div v-if="isOpen" class="languages">
      <div class="languages--item" v-for="lang in languageItems" :key="lang" @click="() => setLanguage(lang.language, lang.isRTL)">
        <div class="d-flex align-items-center">
          <div class="languages--item--icon-container">
            <img v-if="lang.flagLink" :src="lang.flagLink" />
          </div>
          <div>{{ lang.name }}</div>
        </div>
        <div v-if="lang.language === locale" class="languages--item--accept">
           <Icon name="accept"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useUserStore} from "Common/stores/userStore";
import Icon from "Common/components/svg";
import {useSystemStore} from "Common/stores/systemStore";
import {computed, onMounted, onUnmounted, ref} from "vue";

import {useI18n} from "vue-i18n";

const {t, locale} = useI18n({useScope: 'global'});

const userStore = useUserStore();
const systemStore = useSystemStore();

const isLoading = ref(false);
const isOpen = ref(false);

const languageItems = computed(() => {
  return systemStore.translationLanguageList.map(lang => {
    return {
      language: lang.language,
      name: lang.name,
      flagLink: lang.flagLink
    }
  })
});

const flagLink = computed(() => {
  const currentLang = languageItems.value.find(l => l.language === userStore.user?.language);
  if(!currentLang?.flagLink && userStore.user?.language === 'en') return '/flags/en.svg'; 
  return currentLang?.flagLink;
});

const open = () => {
  isOpen.value = !isOpen.value;
}

const close = () => {
  isOpen.value = false;
}
onMounted(() => {
  document.body.addEventListener('click', close);
})
onUnmounted(() => {
  document.body.removeEventListener('click', close);
});

const setLanguage = async (language, isRTL) => {
  if (isLoading.value) return;

  isLoading.value = true;
  try {
    await userStore.setLanguage(language);
    locale.value = language;
  } catch (e) {

  } finally {
    isLoading.value = false;
    isOpen.value = false;
  }
}

</script>
<style lang="scss" scoped>
.language-selector {
  position: relative;
}

.user-icon-container {
  position: relative;
  cursor: pointer;
  padding: 8px;
  border-radius: 50px;
  color: #4E9BF0;

  svg {
    width: 24px;
    height: 24px;
  }
}

.languages {
  background: #fff;
  z-index: 11;
  overflow: hidden;
  position: absolute;
  top: calc(100% + 6px);
  right: -6px;
  width: 185px;
  border-radius: 6px;
  border: solid 1px #A3AED0;

  &--icon-container{
    width: 24px;
    height: 24px;
    > img{
      width: 24px;
      height: 24px;
    }
  }

  &--item {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 16px;
    height: 32px;
    font-size: 14px;
    color: #45557D;

    &--accept{
      width: 18px;
      height: 18px;
      color: #8BC541;
      margin-bottom: 6px;
    }
    
    &--icon-container{
      width: 24px;
      height: 24px;
      padding-top: 1px;
      > img{
        width: 18px;
        height: 18px;
      }
    }
    
    &:deep(svg) {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    &:hover {
      background-color: rgba(78, 155, 240, 0.15);
    }
  }
}

body.rtl {
  .languages {
    left: -6px;
    right: unset;
  
    &--item {
      &:deep(svg) {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }
}
</style>
