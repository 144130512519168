<template>
  <div class="base-input">
    <div v-if="name" class="base-input-header">
      <div class="base-input-label">
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div>
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <div class="base-dropdown-container scroll-transparent" :class="'p-icon-'+customIcons?.length + (topFilterStyle ? ' top-filter-style ' : '')">
      <Multiselect
          :mode="mode"
          :multipleLabel="theLabelFunction"
          :hideSelected="false"
          :createOption="createOption"
          :appendNewOption="appendNewOption"
          :appendNewTag="appendNewTag"
          :searchable="searchable"
          :autocomplete="autocomplete"
          :canClear="canClear"
          :canDeselect="canClear"
          :disabled="disabled"
          :value="modelValue"
          :options="options"
          :filter-results="filterResults"
          :min-chars="minChars"
          :delay="delay"
          :placeholder="placeholder"
          :closeOnSelect="closeOnSelect"
          :on-create="onCreate"
          @select="onSelect"
          @deselect="onDeselect"
          @change="onChange"
          @clear="onClear"
          ref="refMultiselect"
      >
        <template v-if="htmlOptions" v-slot:singlelabel="{ value }">
          <div class="single-label">{{getSingleLabel(value.label)}}</div>
        </template>
        <template v-if="htmlOptions || customOptions" v-slot:option="{ option }">
          <slot name="option" :option="option">
            <div v-html="option.label"></div>
          </slot>
        </template>
      </Multiselect>
      <span v-if="customIcons?.length > 0" class="base-dropdown-container-icons ml-1">
        <span v-for="item in customIcons">
          <component :is="item.component" v-bind="item.props"/>
        </span>
      </span>
    </div>
    <div v-if="error" class="base-input-error">
      <Icon name="error"/>
      <div v-html="error"></div>
    </div>
  </div>
</template>

<script setup>

import Icon from 'Common/components/svg';
import {useI18n} from "vue-i18n";
import {shallowRef} from "vue";

const props = defineProps({
  name: String,
  isRequired: {
    type: Boolean,
    default: false
  },
  customIcons: Array, // [ {component, props} ]
  disabled: {type: Boolean, default: false},
  modelValue: {},
  options: {},
  htmlOptions: Boolean,
  customOptions: Boolean,
  createOption: {
    type: Boolean,
    default: false,
  },
  appendNewOption: {
    type: Boolean,
    default: false,
  },
  appendNewTag: {
    type: Boolean,
    default: false,
  },
  createTag: {
    type: Boolean,
    default: false,
  },
  onCreate: {
    type: Function,
    default: undefined,
  },
  searchable: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: "off"
  },
  mode: {
    type: String,
    default: 'single'
  },
  canClear: {
    type: Boolean,
    default: true
  },
  error: String,
  filterResults: {},
  delay: {},
  minChars:{},
  placeholder: String,
  topFilterStyle: Boolean,
  closeOnSelect: {
      type: Boolean,
      default: true
  },
  labelFunction: Function,
});
const emit = defineEmits(['update:modelValue', 'select', 'deselect', 'clear']);

const {t} = useI18n({useScope: 'global'})

const refMultiselect = shallowRef(null);

function theLabelFunction(n) {
  if(props.labelFunction) return props.labelFunction(n);
  return `${n.length} ${t('common.selectedDots')}`;
}

function getSingleLabel(text){
  const div = document.createElement("div");
  div.innerHTML = text;
  return div.textContent || div.innerText || "";
}

function onChange(v) {
  emit('update:modelValue', v);
}
function onSelect(v) {
  emit('select', v);
}
function onDeselect(v) {
  emit('deselect', v);
}
function onClear(v) {
  emit('clear', v);
}

defineExpose({
  clearSearch: () => { refMultiselect.value?.clearSearch && refMultiselect.value?.clearSearch(); }
})

</script>

<style lang="scss" scoped>
  .base-input{
    display: flex;
    flex-direction: column;
    .base-input-label{
      line-height: 14px;
      margin-bottom: 8px;
    }
    .base-input-error{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 2px;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      svg{
        min-width: 16px;
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  .base-dropdown-container-icons{
    position: absolute;
    right: 24px;
  }
  .base-dropdown-container{
    display: flex;
    align-items: center;
    position: relative;
    &.p-icon-0:deep(.multiselect){
      padding-right: 24px;
    }
    &.p-icon-1:deep(.multiselect){
      padding-right: 54px;
    }
    &.p-icon-2:deep(.multiselect){
      padding-right: 78px;
    }
    &:deep(.multiselect){
      height: 48px;
      padding: 17px 16px;
      border: 1px solid white;
      border-radius: 8px;
      background-color: inherit;
      outline: none;
      font-family: inherit;
      font-size: inherit;
      &.is-active{
        box-shadow: none;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        .multiselect-caret{
          transform: rotate(-90deg);
        }
      }
      .multiselect-single-label-text{
        font-weight: inherit;
      }
      .multiselect-search,
      .multiselect-multiple-label,
      .multiselect-placeholder,
      .multiselect-single-label{
        padding-left: 0;
      }
      .multiselect-clear{
        padding: 0;
      }

      .multiselect-dropdown{
        border: 1px solid white;
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        p{
          margin-bottom: 0;
        }
      }

      .multiselect-search{
        background-color: inherit;
      }
    }
  }
  .single-label{
    width: 100%;
    max-height: 40px;
    overflow: hidden;
  }

  body.rtl {
    .base-input{
      .base-input-error{
        svg{
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }
    .base-dropdown-container-icons{
      left: 24px;
      right: unset;
    }
    .base-dropdown-container{
      &.p-icon-1:deep(.multiselect){
        padding-left: 54px;
        padding-right: 0;
      }
      &.p-icon-2:deep(.multiselect){
        padding-left: 78px;
        padding-right: 0;
      }
      &:deep(.multiselect){
        .multiselect-search,
        .multiselect-multiple-label,
        .multiselect-placeholder,
        .multiselect-single-label{
          padding-right: 1px;
          padding-left: 0;
          right: 0;
          left: unset;
        }

        .multiselect-placeholder {
          right: 0;
          left: unset;
        }
        
      }
    }
  }
</style>
