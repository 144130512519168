export const environment = {
  production: process.env.VUE_APP_PRODUCTION.toLowerCase() === 'true',
  name: process.env.VUE_APP_NAME,

  affiliateDomain: process.env.VUE_APP_AFFILIATE_DOMAIN,
  customerDomain: process.env.VUE_APP_CUSTOMER_DOMAIN,

  paypalDomain: process.env.VUE_APP_PAYPAL_URL,

  notificationServiceUrl: process.env.VUE_APP_NOTIFICATIONS_SERVICE_URL,
  systemServiceUrl: process.env.VUE_APP_SYSTEM_SERVICE_URL,
  documentationServiceUrl: process.env.VUE_APP_DOCUMENTATION_SERVICE_URL,
  imagesUrl: process.env.VUE_APP_IMAGES_URL,
  usersManagementServiceUrl: process.env.VUE_APP_USERS_MANAGEMENT_SERVICE_URL,
  apiKeysServiceUrl: process.env.VUE_APP_API_KEYS_SERVICE_URL,
  sitesServiceUrl: process.env.VUE_APP_SITES_SERVICE_URL,
  customerSupportService: process.env.VUE_APP_CUSTOMER_SUPPORT_SERVICE_URL,
  organizationsManagementServiceUrl: process.env.VUE_APP_ORGANIZATIONS_MANAGEMENT_SERVICE_URL,
  organizationsLocalServiceUrl: process.env.VUE_APP_ORGANIZATIONS_LOCAL_SERVICE_URL,
  billingServiceUrl: process.env.VUE_APP_BILLING_SERVICE_URL,
  reportServiceUrl: process.env.VUE_APP_REPORT_SERVICE_URL,
  manualLicenseServiceUrl: process.env.VUE_APP_MANUAL_LICENSE_SERVICE_URL,
  affiliationServiceUrl: process.env.VUE_APP_AFFILIATION_SERVICE_URL,
  researchServiceUrl: process.env.VUE_APP_RESEARCH_SERVICE_URL,
  analyzeFileServiceUrl: process.env.VUE_APP_ANALYZE_FILE_SERVICE_URL,
  apptoneServiceUrl: process.env.VUE_APP_APPTONE_SERVICE_URL,
  feelGptServiceUrl: process.env.VUE_APP_FEELGPT_SERVICE_URL,
  pushServiceListenerUrl: process.env.VUE_APP_PUSH_SERVICE_LISTENER_URL,
  pushServiceListenerPath: process.env.VUE_APP_PUSH_SERVICE_LISTENER_PATH || '/push-service/socket.io/',
  discoveryReportServiceUrl: process.env.VUE_APP_DISCOVERY_REPORT_URL,
  emotionDiamondServiceUrl: process.env.VUE_APP_EMOTION_DIAMOND_SERVICE_URL,
  analyzeNowServiceUrl: process.env.VUE_APP_ANALYZE_NOW_SERVICE_URL,
  distributorsServiceUrl: process.env.VUE_APP_DISTRIBUTORS_SERVICE_URL,

  push :{
    protocol: process.env.VUE_APP_PUSH_PROTOCOL,
    host: process.env.VUE_APP_PUSH_HOST,
    path: process.env.VUE_APP_PUSH_PATH,
    port: process.env.VUE_APP_PUSH_PORT
  },

  passwordRegex: process.env.VUE_APP_PASSWORD_REGEX,
};
